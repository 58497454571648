<template>
  <div>
    <div>
      <h3>使用方法：</h3>
      <ul class="li">
        1.使用签名工具生成签名，记得边框显示选择为不显示，下载图片上传至图床
      </ul>
      <ul class="li">
        2.获取图床生成的链接粘贴至图片粘贴处
      </ul>
      <ul class="li">
        3.填写本校请假所需信息，除本人签名项不需要填写（已内置）
      </ul>
    </div>
    <div>
      <h3>所需工具：</h3>
      <ul class="li">
        1.签名网站：<a href="https://langyuxiansheng.github.io/vue-sign-canvas/" target="_blank" rel="noopener noreferrer">https://langyuxiansheng.github.io/vue-sign-canvas/</a>
      </ul>
      <ul class="li">
        2.图床服务：<a href="https://pic.jsproxy.cyou/" target="_blank" rel="noopener noreferrer">https://pic.jsproxy.cyou/</a>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Instr",
  data() {
    return {};
  },
};
</script>